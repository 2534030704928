.categoryListSection-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 42px;
}
    .categoryListSection-container-text {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;
    }
        .categoryListSection-container-text-title{
            text-align: center;
            color: var(--black);
        }    
        .categoryListSection-container-text-subtitle{
            color: var(--gray300);
        }
    .categoryListSection-container-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
        .categoryListSection-container-list > * {
            margin-right: 33px;
        }

@media only screen and (max-width: 37.5em) {
    .categoryListSection-container-text {
        margin-bottom: 22px;
    }
    .categoryListSection-container-list {
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        justify-content: unset;
        width: unset;
        padding-left: 15px;
    }
        .categoryListSection-container-list > * {
            margin-right: 15px;
        }
}