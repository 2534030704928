.FormFieldGeo-container {
    display: flex;
    width: 100%;
    height: 600px;
}
    .FormFieldGeo-container-markerContainer {
        height: 40px;
        width: 40px;
    }
        .FormFieldGeo-container-markerContainer-img {
            width: 100%;
            margin: 0;
        }
    .FormFieldGeo-container-addresses {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1000;
        margin-top: 40px;
        margin-left: 30px;
        width: 40%;
        background: var(--white);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        padding: 10px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldGeo-container-addresses {
                margin-top: 30px;
                margin-left: 20px;
                right: 0;
                left: 0;
                width: 85%;
            }}
        .FormFieldGeo-container-addresses-inputContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
            .FormFieldGeo-container-addresses-inputContainer-input {
                border: none;
                background-color: transparent;
                font-style: normal;
                font-weight: 300;
                font-size: 1.25rem;
                line-height: 24px;
                color: var(--black);
                margin-left: 5px;
                width: 100%;
            }
            .FormFieldGeo-container-addresses-inputContainer-input:focus {
                outline: none;
            }
        .FormFieldGeo-container-addresses-list {

        }
            .FormFieldGeo-container-addresses-list-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 20px 0;
            }
                .FormFieldGeo-container-addresses-list-item-text {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.9375rem;
                    line-height: 20px;
                    color: var(--black);
                    margin: 0;
                    margin-left: 10px;
                }