.siniestroAlert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .siniestroAlert-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .siniestroAlert-container-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        width: 70%;
    }
    .siniestroAlert-container-botton {
        width: 50%;
    }
        @media only screen and (max-width: 37.5em) {
            .siniestroAlert-container-botton {
                width: 90%;
            }
        }