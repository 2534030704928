.FormFieldImageOption-inputContainer {
    width: 100%;
}
    .FormFieldImageOption-inputContainer-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        color: #999999;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        text-overflow: ellipsis;
    }
    .FormFieldImageOption-inputContainer-listContainer {
        display: flex;
        flex-direction: row;
    }
        .FormFieldImageOption-inputContainer-listContainer-item {
            background: var(--white);
            border: 0.788701px solid var(--gray100);
            box-sizing: border-box;
            box-shadow: 0px 3.1548px 3.1548px rgba(0, 0, 0, 0.1);
            border-radius: 19.7175px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 130.92px;
            height: 130.92px;
            margin-right: 20px;
            cursor: pointer;
        }
            .FormFieldImageOption-gradient {
                background: linear-gradient(42.94deg, var(--primaryGradientColor) 16.67%, var(--secondaryGradientColor) 51.14%, var(--thirdGradientColor) 83.08%);
            }
            .FormFieldImageOption-inputContainer-listContainer-item-img {
                margin: 0 auto;
                height: auto;
                width: 30%;
            }
                .FormFieldImageOption-white {
                    filter: brightness(0) invert(1);
                }
            .FormFieldImageOption-inputContainer-listContainer-item-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                text-align: center;
                color: #7963E0;                
            }