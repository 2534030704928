.checkOutDataComponent-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid var(--gray500);
    margin: 15px 0;
}
    @media only screen and (max-width: 37.5em) {
        .checkOutDataComponent-container {
            border-bottom: 0.5px solid var(--gray500);
        }}
    .checkOutDataComponent-container-title {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 24px;
        margin: 0;
        color: var(--gray500);
        margin-bottom: 10px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutDataComponent-container-title {
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                line-height: 24px;
                margin: 0;
                margin-bottom: 10px;
            }}
    .checkOutDataComponent-container-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 24px;
        color: var(--black);
        margin: 0;
        margin-bottom: 10px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutDataComponent-container-description {
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                line-height: 24px;
                margin: 0;
                margin-bottom: 10px;
            }}