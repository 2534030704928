.infoSection-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    padding-top: 42px;
}
    .infoSection-container-title {
        color: var(--black);
        text-align: center;
        margin-bottom: 32px;
    }
    .infoSection-container-list {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
    }
        .infoSection-container-list-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 174px;
        }
            @media only screen and (max-width: 37.5em) {
                .infoSection-container-list-item {
                    margin-bottom: 20px;
                }
            }
            .infoSection-container-list-item-img {
                width: 128px;
                height: 128px;
                margin-bottom: 32px;
            }
            .infoSection-container-list-item-text {
                text-align: center;
                color: var(--primary500);
            }