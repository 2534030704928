.checkoutFin-container {
    width:100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
}
    .checkoutFin-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 35%;
    }
    .checkoutFin-container-main-img {
        width: 40%;
    }
    @media only screen and (max-width: 37.5em) {
        .checkoutFin-container-main {
            width: 90%;
        }}
        .checkoutFin-container-main-bot {
            margin-bottom: 16px;
            width: 50%;
        }
        .checkoutFin-container-main-title {
            color: var(--gray700);
            text-align: center;
            margin-top: 40px;
        }
        .checkoutFin-container-main-subTitle {
            color: var(--gray700);
            text-align: center;
            margin-top: 8px;
        }
        .checkoutFin-container-main-description {
            color: var(--gray700);
            text-align: center;
            margin: 32px 0;
        }
        .checkoutFin-container-main-space {
            height: 16px;
        }
    .checkoutFin-container-wave {
        margin-top: auto;
    }