.FormFieldCarLicence-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldCarLicence-container {
            margin-left: 20px;
    }}
    .FormFieldCarLicence-container-label {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
        .FormFieldCarLicence-container-label-title {
            font-style: normal;
            font-weight: 325;
            font-size: 1.25rem;
            color: #0075BC;
            margin: 0;            
        }
        .FormFieldCarLicence-container-label-req {
            font-style: normal;
            font-weight: 325;
            font-size: 1.25rem;
            color: #EF052E;
            margin: 0;     
        }
    .FormFieldCarLicence-container-main {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldCarLicence-container-main {
                flex-direction: column;
                align-items: flex-start;
        }}
        .FormFieldCarLicence-container-main-input {
            border: none;
            background-color: transparent;
            outline: none;
            font-style: normal;
            font-weight: 300;
            font-size: 1.25rem;
            border-bottom-style: solid;
            border-bottom-color: var(--gray400);
            border-bottom-width: 1px;
            padding-bottom: 5px;
            padding-left: 5px;
            color: var(--black);
        }
            .FormFieldCarLicence-container-main-input::placeholder {
                color: rgb(171, 171, 171);
            }
            @media only screen and (max-width: 37.5em) {
                .FormFieldCarLicence-container-main-input {
                    width: 87%;
            }}
        .FormFieldCarLicence-container-main-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 25px;
            background-color: var(--primary500);
            transition: all 0.1s ease-in;
            cursor: pointer;
            margin-left: 10px;
        }
        @media only screen and (max-width: 37.5em) {
            .FormFieldCarLicence-container-main-next {
                display: none;
        }}
            .FormFieldCarLicence-container-main-next:active {
                transform: scale(0.8);
            }
        .FormFieldCarLicence-container-main-okm {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 40px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldCarLicence-container-main-okm {
                    margin-left: 0;
                    margin-top: 20px;
            }}
            .FormFieldCarLicence-container-main-okm-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border: 2px solid var(--primary500);
                cursor: pointer;
                margin-right: 10px;
            }
                .FormFieldCarLicence-container-main-okm-box-inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                    height: 16px;
                    background-color: var(--primary500);
                    transition: all 0.25s ease;
                }
            .FormFieldCarLicence-container-main-okm-text {
                font-style: normal;
                font-weight: 325;
                font-size: 0.9rem;
                color: var(--primary500);
                margin: 0;
            }
    .FormFieldCarLicence-container-error {
        font-style: normal;
        font-weight: 325;
        font-size: 0.9rem;
        color: red;
        margin: 0;
    }
    .FormFieldCarLicence-container-buttonWrapper {
        display: none;
        width: 90%;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldCarLicence-container-buttonWrapper {
                display: flex;
                margin-top: 40px;
        }}