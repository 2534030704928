.accordionCotizar-container {
    display: flex;
    flex-direction: column;
    background: var(--white);
    box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
    border-radius: 20px;
    padding: 15px 40px;
    margin-bottom: 20px;
    width: 95%;
    cursor: pointer;
    transition: 2s;
}
    @media only screen and (max-width: 37.5em) {
        .accordionCotizar-container {
            width: 90%;
            padding: 15px 20px;
        }}
    .accordionCotizar-container-index {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
        .accordionCotizar-container-index-title {
            color: var(--primary500);
        }
    .accordionCotizar-container-detailClose {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: 0px;
        transition: all 0.6s ease-in-out;
        text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-property: max-height 0.8s ease;
    }
    .accordionCotizar-container-detailOpen {
        display: flex;
        flex-direction: column;
        max-height: 1000px;
        transition: all 0.6s ease-in-out;
        text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-property: max-height 0.8s ease;
    }
        .accordionCotizar-container-detail-line {
            display: flex;
            width: 100%;
            height: 1px;
            background-color: var(--primary500);
            margin: 10px 0;
        }
        .accordionCotizar-container-detail-text {
            color: var(--gray300);
        }
            .accordionCotizar-container-detail-text a {
                color: var(--secondary500);
                cursor: pointer;
                font-weight: 700;
                text-decoration: none;
            }