.checkOutProfile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 50px;
}
    @media only screen and (max-width: 37.5em) {
        .checkOutProfile-container {
            width: 95%;
            flex-direction: column;
        }}
    .checkOutProfile-container-endWrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-right: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-endWrapper {
                width: 100%;
                position: fixed;
                bottom: 0;
                background-color: var(--white);
                z-index: 20;
                align-items: center;
                justify-content: center;
                box-shadow: 0px -4px 30px 0px rgba(0, 0, 0, 0.10);
            }}
        .checkOutProfile-container-endWrapper-main {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutProfile-container-endWrapper-main {
                    width: 90%;
                    margin-bottom: 10px;
                }}
    .checkOutProfile-container-main {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-left: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-main {
                width: 91%;
                margin-left: 25px;
                padding-bottom: 300px;
            }}
        .checkOutProfile-container-main-titulo {
            color: var(--gray700);
            margin-bottom: 4px;
        }
        .checkOutProfile-container-main-volver {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary500);
            margin-bottom: 24px;
            cursor: pointer;
        }
        .checkOutProfile-container-main-reLogText {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: auto;
            margin-bottom: 24px;
        }
            .checkOutProfile-container-main-reLogText p {
                color: var(--primary500);
                cursor: pointer;
                font-weight: 700;
            }

        .checkOutProfile-container-main-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 97.5%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutProfile-container-main-list {
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: nowrap;
                    width: 100%;
                }}
            .checkOutProfile-container-main-list-invis {
                width: 31%;
                margin-bottom: 30px;
            }
            .checkOutProfile-container-main-list > * {
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list > * {
                        width: 100%;
                    }}