.demoTipografiaSelector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .demoTipografiaSelector-container-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .demoTipografiaSelector-container-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
            .demoTipografiaSelector-container-list-item-text {
                cursor: pointer;
            }