.customDateInput-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.customDateInput-container-label {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.customDateInput-container-label-requiered{
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 24px;
    color: red;
    margin: 0;
}

.customDateInput-container-inputContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.customDateInput-container-inputContainer-input{
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 24px;
    color: var(--black);
    width: 100%;
}