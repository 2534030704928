.FormFieldOption-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--gray200);
    padding-bottom: 20px;
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldOption-container {
            flex-direction: column;
        }}
    .FormFieldOption-container-text {
        color: var(--gray700);
    }
    .FormFieldOption-container-list {
        display: flex;
        flex-direction: row;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldOption-container-list {
                margin-top: 10px;
            }}
        .FormFieldOption-container-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 10px;
        }
            .FormFieldOption-container-list-item-circle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border: 2px solid var(--primary500);
                cursor: pointer;
                margin-right: 5px;
                border-radius: 25px;
            }
                .FormFieldOption-container-list-item-circle-inner {
                    width: 14px;
                    height: 14px;
                    background-color: var(--primary500);
                    transition: all 0.25s ease;
                    border-radius: 25px;
                }
            .FormFieldOption-container-list-item-text {
                color: var(--primary500);
            }