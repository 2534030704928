.FormFieldInfo-container {
    display: flex;
    width: 100%;
    background: var(--white);
    box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
}
    .FormFieldInfo-container-main {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding: 10px 0;
        transition: all 1s ease;
    }
        .FormFieldInfo-container-main-titleContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
            .FormFieldInfo-container-main-titleContainer-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1.5rem;
                color: var(--primary500);
                margin: 0;           
            }
        .FormFieldInfo-container-main-textContainer {
            flex-direction: column;
            transition: all 1s ease;
        }
            .FormFieldInfo-container-main-textContainer-line {
                width: 100%;
                height: 0.1px;
                background-color: var(--primary500);
                margin: 10px 0
            }
            .FormFieldInfo-container-main-textContainer-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1.25rem;
                color: #999999;
                margin: 0;     
            }