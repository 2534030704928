.socialLinks-container{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    transition: all 0.5s ease-in;
}
    @media only screen and (max-width: 37.5em) {
        .socialLinks-container{
            bottom: 40px;
        }}
    .socialLinks-container-list {
        display: flex;
        flex-direction: column;
        margin-left: 2.5px;
    }
        @media only screen and (max-width: 37.5em) {
            .socialLinks-container-list {
                margin-left: 5px;
            }}
        .socialLinks-container-list-item {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.5s ease-in;
            background-color: white;
            position: absolute;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
            border-radius: 100px;
            height: 40px;
            width: 40px;
        }
            @media only screen and (max-width: 37.5em) {
                .socialLinks-container-list-item {
                    width: 65px;
                    height: 65px;
                }}
    .socialLinks-container-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 7.18533px 26.945px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        width: 50px;
        height: 50px;
        z-index: 10;
        cursor: pointer;
    }
        @media only screen and (max-width: 37.5em) {
            .socialLinks-container-menu {
                width: 75px;
                height: 75px;
            }}
