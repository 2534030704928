.perfilHome-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .perfilHome-container {
            width: 95%;
            flex-direction: column;
            position: relative;
            overflow-x: hidden;
        }}
    .perfilHome-container-main {
        display: flex;
        flex-direction: column;
        width: 94%;
        margin: 0 auto;
    }
        @media only screen and (max-width: 37.5em) {
            .perfilHome-container-main {
                width: 91%;
                margin-left: 25px;
                padding-bottom: 30px;
            }}
            .perfilHome-container-main-edit-accion {
                display: flex;
                position: fixed;
                bottom: 78px;
                right: 50px;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border: 1px solid var(--primary500);
                cursor: pointer;
                border-radius: 100px;
                background: var(--primary500);
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-edit-accion {
                        bottom: 5%;
                        right: 5%;
                    }}
            .perfilHome-container-main-edit-buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 15%;
                position: fixed;
                bottom: 78px;
                right: 50px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-edit-buttons {
                        width: 100%;
                        bottom: 0;
                        right: 0;
                        justify-content: space-around;
                        padding: 10px 0;
                        background-color: var(--white);
                    }}
            .perfilHome-container-main-edit-buttons > * {
                width: 100%;
                margin-left: 10px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-edit-buttons > * {
                        width: 45%;
                        margin-left: 0;
                    }}
        .perfilHome-container-main-superTitulo {
            margin: 30px 0;
        }
        .perfilHome-container-main-titulo {
            color: var(--gray700);
            margin-bottom: 4px;
        }
        .perfilHome-container-main-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 75%;
        }
            @media only screen and (max-width: 37.5em) {
                .perfilHome-container-main-list {
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: nowrap;
                    width: 100%;
                }}
            .perfilHome-container-main-list-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 31%;
            }
                .perfilHome-container-main-list-list > * {
                    width: 31%;
                }
            .perfilHome-container-main-list-invis {
                width: 31%;
                margin-bottom: 30px;
            }
            .perfilHome-container-main-list > * {
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list > * {
                        width: 100%;
                    }}
            .perfilHome-container-main-list-item-selectorWrapper {
                display: flex;
                flex-direction: column;
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-item-selectorWrapper {
                        width: 100%;
                    }}
                .perfilHome-container-main-list-item-selectorWrapper-title {
                    color: var(--tertiary500);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                }
                .perfilHome-container-main-list-item-selectorWrapper-title ul {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: red;
                    margin: 0;
                    padding: 0;
                }
            .perfilHome-container-main-list-innerList {
                display: flex;
                flex-direction: row;
                width: 103%;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-innerList {
                        width: 105%;
                    }}

                    .perfilHome-container-main-list-item-specialInput {
                        display: flex;   
                        flex-direction: column;
                        justify-content: flex-start;
                        width: 30%;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .perfilHome-container-main-list-item-specialInput {
                                width: 100%;
                            }}
                        .perfilHome-container-main-list-item-specialInput-label {
                            display: flex;
                            flex-direction: row;
                        }
                            .perfilHome-container-main-list-item-specialInput-label-text {
                                color: var(--tertiary500);
                            }
                            .perfilHome-container-main-list-item-specialInput-label-requiered {
                                color: var(--statusErrorPrimary);
                            }
                        .perfilHome-container-main-list-item-specialInput-main {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }
                            .perfilHome-container-main-list-item-specialInput-main-input {
                                border: none;
                                background-color: transparent;
                                font-style: normal;
                                font-weight: 300;
                                font-size: 1.25rem;
                                line-height: 24px;
                                color: var(--black);
                                width: 100%;
                                font-family: Lato, sans-serif;
                                padding: 10px;
                                border-bottom: 1px solid var(--gray300);
                            }
                            .perfilHome-container-main-list-item-specialInput-main-input:focus {
                                outline: none;
                            }
                            .perfilHome-container-main-list-item-specialInput-main-input::placeholder {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 1rem;
                                line-height: 1.5rem;                
                                color: var(--gray300);
                                font-family: Lato, sans-serif;
                            }
                            .perfilHome-container-main-list-item-specialInput-main-line {
                                color: var(--gray300);
                                margin: 0 10px;
                            }
                            .perfilHome-container-main-list-item-specialInput-main-status {
                                margin-left: -20px;
                            }
