.faqSection-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    padding-top: 42px;
}
    .faqSection-container-title {
        color: var(--black);
        text-align: center;
        margin-bottom: 32px;
    }
    .faqSection-container-list {
        display: flex; 
        flex-direction: column; 
        align-self: center; 
        align-items: center;
        width: 90%
    }