.FormFieldList-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    height: 100%;
    min-height: 500px;
}
    .FormFieldList-container-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .FormFieldList-container-empty-title {
            text-align: center;
            color: var(--black);
            margin: 10px 0;
        }
        .FormFieldList-container-empty-text {
            text-align: center;
            color: var(--black);
        }
    .FormFieldList-container-buscador {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 25%;
        background-color: var(--white);
        border: 1px solid var(--gray200);
        border-radius: 15px;
        padding: 10px;
        margin-bottom: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldList-container-buscador {
                width: 82.5%;
                margin-left: 20px;
        }}
        .FormFieldList-container-buscador-input {
            outline: none;
            border: none;
            width: 90%;
        }
        .FormFieldList-container-buscador-input::placeholder {
            color: var(--gray400);
        }
    .FormFieldList-container-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        gap: 20px; 
        margin-top: 40px;
    }
        .FormFieldList-container-list-item {
            display: flex;
            border-bottom: 1px solid var(--primary200);
            cursor: pointer;
            color: var(--gray700);
            width: calc(25% - 15px);
            margin-top: -20px;
        }
        .FormFieldList-container-list-item:hover {
            border-bottom: 1px solid var(--primary200);
            background-color: var(--primary200);
        }
        .FormFieldList-container-list-item:active {
            border-bottom: 1px solid var(--primary500);
            background-color: var(--primary500);
            color: var(--white);
        }
            .FormFieldList-container-list-item-text {
                padding-bottom: 8px;
                padding-left: 8px;
                padding-top: 8px;
            }

.FormFieldList-container-loader {
    width: 20px;
    height: 20px;
    border: 2px solid var(--primary500);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: auto;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 