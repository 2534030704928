.checkOutResume-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 150px;
}
    @media only screen and (max-width: 37.5em) {
        .checkOutResume-container {
            flex-direction: column;
            padding-bottom: 100px;
        }}
    .checkOutResume-container-detailsWrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-right: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutResume-container-detailsWrapper {
                display: none;
            }}
    .checkOutResume-container-mainDesktop {
        display: flex;
        flex-direction: column;
        width: 65%;
        margin-left: 50px;
        margin-right: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutResume-container-mainDesktop {
                width: 90%;
                margin-left: 20px;
                margin-right: 0px;
            }}
        .checkOutResume-container-mainDesktop-subTitle {
            font-style: normal;
            font-weight: bold;
            font-size: 1.25rem;
            line-height: 24px;
            color: var(--black);
            margin: 0;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .checkOutResume-container-mainDesktop-details {
            display: flex;   
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }
            .checkOutResume-container-mainDesktop-details-itemWrapper {
                width: 48%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutResume-container-mainDesktop-details-itemWrapper {
                        width: 100%;
                    }}
                .checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
                    .checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-medioText {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--black);
                        margin: 0;
                    }
                    .checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-tarjetaText {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--black);
                        margin: 0;
                        opacity: 0.4;
                    }
                .checkOutResume-container-mainDesktop-details-itemWrapper-otroText {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 19px;
                    text-align: center;
                    color: var(--primary500);
                    margin: 0;
                    cursor: pointer;
                    margin-top: 20px;
                }
                    @media only screen and (max-width: 37.5em) {
                        .checkOutResume-container-mainDesktop-details-itemWrapper-otroText {
                            margin-bottom: 20px;
                        }}
                .checkOutResume-container-mainDesktop-details-itemWrapper-tos {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 100%;
                }
                    .checkOutResume-container-mainDesktop-details-itemWrapper-tos-text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 0.8125rem;
                        line-height: 16px;
                        color: var(--black);
                        margin: 0;
                        width: 92.5%;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .checkOutResume-container-mainDesktop-details-itemWrapper-tos-text {
                                width: 90%;
                            }}
                        .checkOutResume-container-mainDesktop-details-itemWrapper-tos-text a { 
                            text-decoration: none;
                            cursor: pointer;
                            color: var(--primary500);
                        }
                    .checkOutResume-container-mainDesktop-details-itemWrapper-tos-checkContainer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--white);
                        border: 2px solid var(--primary500);
                        box-sizing: border-box;
                        border-radius: 4px;
                        height: 24px;
                        width: 24px;
                        cursor: pointer;
                    }
                        .checkOutResume-container-mainDesktop-details-itemWrapper-tos-checkContainer-inner {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            transition: all 0.5s ease;
                            height: 24px;
                            width: 24px;
                            margin: 0;
                            padding: 0;
                        }
        .checkOutResume-container-mainDesktop-title {
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 29px;
            display: flex;
            align-items: center;
            color: var(--primary500);
            margin: 0;
        }
    .checkOutResume-container-responsivePayContainerMask {
        display: flex;
        background: var(--primary500);
        height: 40px;
        width: 100%;
        z-index: -100;
    }
        .checkOutResume-container-responsivePayContainerMask-inner {
            display: flex;
            background: var(--gray50);
            height: 100%;
            width: 100%;
            border-bottom-left-radius: 50px;
        }
    .checkOutResume-container-responsivePayContainer {
        display: none;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutResume-container-responsivePayContainer {
                display: flex;
                flex-direction: column;
                position: fixed;
                bottom: 0;
                width: 100%;
            }}
        .checkOutResume-container-responsivePayContainer-buttonWrapper {
            display: flex;
            flex-direction: column;
            width: 90%;
        }
        .checkOutResume-container-responsivePayContainer-main {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border-radius: 0 50px 0 0;
            background-color: var(--primary500); 
            padding-top: 10px;
            padding-bottom: 20px;
        }
            .checkOutResume-container-responsivePayContainer-main-codeError {
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 24px;
                color: var(--white);
                margin: 0;
                margin-top: 10px;
            }
            .checkOutResume-container-responsivePayContainer-main-codeSuccess {
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 24px;
                color: var(--white);
                margin: 0;
                margin-top: 10px;
            }
            .checkOutResume-container-responsivePayContainer-main-buttonWrapper {
                width: 80%;
            }
            .checkOutResume-container-responsivePayContainer-main-codeContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 90%;
                border-bottom: 0.5px solid var(--white);
            }
                .checkOutResume-container-responsivePayContainer-main-codeContainer-deleteCodeText {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.875rem;
                    line-height: 24px;
                    color: var(--fifthTextColor);
                    margin: 0;
                }
                .checkOutResume-container-responsivePayContainer-main-codeContainer-input {
                    border: none;
                    background-color: transparent;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 0.75rem;
                    line-height: 24px;
                    color: var(--white);
                    width: 100%;
                    margin-left: 20px;
                }
                .checkOutResume-container-responsivePayContainer-main-codeContainer-input::placeholder {
                    color: var(--white);
                }
                .checkOutResume-container-responsivePayContainer-main-codeContainer-input:focus::placeholder {
                    opacity: 0.3;
                }
                .checkOutResume-container-responsivePayContainer-main-codeContainer-input:focus {
                    outline: none;
                }
            .checkOutResume-container-responsivePayContainer-main-price {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 90%;
                margin: 10px 0;
            }
                .checkOutResume-container-responsivePayContainer-main-price-text {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 0.9375rem;
                    line-height: 29px;
                    color: var(--white);       
                    margin: 0;         
                }
                .checkOutResume-container-responsivePayContainer-main-price-price {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.5625rem;
                    line-height: 42px;
                    color: var(--white);
                    margin: 0;
                }