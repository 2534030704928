.PatChileConfigComponent-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}
    .PatChileConfigComponent-container-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .PatChileConfigComponent-container-row {
                flex-wrap: wrap;
                margin-bottom: 0;
            }}
        .PatChileConfigComponent-container-row-item {
            width: 45%;
        }
            @media only screen and (max-width: 37.5em) {
                .PatChileConfigComponent-container-row-item {
                    width: 100%;
                    margin-bottom: 20px;
                }}
            .PatChileConfigComponent-container-row-item-input {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                margin-left: -10px;
                margin-top: -20px;
            }
                @media only screen and (max-width: 37.5em) {
                    .PatChileConfigComponent-container-row-item-input {
                        width: 95%;
                    }}
            .PatChileConfigComponent-container-row-item-label {
                display: flex;
                flex-direction: row;
            }
                .PatChileConfigComponent-container-row-item-label-text {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: #0075BC;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                }
                .PatChileConfigComponent-container-row-item-label-requiered {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: red;
                    margin: 0;
                }
            .PatChileConfigComponent-container-row-item-cuota {
                font-style: normal;
                font-weight: 325;
                font-size: 2rem;
                color: var(--primary500);
                margin: 0;
            }
            .PatChileConfigComponent-container-row-item-emisoraList {
                display: flex;
                flex-direction: row;
            }