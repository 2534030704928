.demoStart-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    align-items: center;
    justify-content: center;
}
    .demoStart-container-input {
        width: 50%;
        margin: 10px 0;
    }
    .demoStart-container-button {
        width: 50%;
        margin-top: 10px;
    }