.FormFieldLabel-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
    .FormFieldLabel-container-text {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        color: #333333;
        margin: 0;    
    }