.cotizar-container {
      padding:0;
      margin:0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      position: fixed;
}
      @media only screen and (max-width: 37.5em) {
            .cotizar-container {
                  max-height: unset;
            }}
      .cotizar-container-mainLoading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 80%;
      }
            @media only screen and (max-width: 37.5em) {
                  .cotizar-container-mainLoading {
                        height: 100%;
                  }}
            .cotizar-container-mainLoading-text {
                  text-align: center;
                  color: var(--primary500);
                  margin-top: -150px;
            }
      .cotizar-container-main {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-self: center;
      }