.changePassword-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .changePassword-container-main {
        display: flex;
        flex-direction: column;
        width: 320px;
        margin: auto;
    }
        @media only screen and (max-width: 37.5em) {
            .changePassword-container-main {
                width: 90%;
                margin-top: 20px;
            }
        }
        .changePassword-container-main-title {
            color: var(--gray700);
            text-align: center;
            margin: auto;
            margin-top: 24px;
            margin-bottom: 54px;
        }
        @media only screen and (max-width: 37.5em) {
            .changePassword-container-main-title {
                margin: 0;
                margin-right: auto;
                margin-top: 32px;
                margin-bottom: 40px;
            }
        }
        .changePassword-container-main-notice {
            display: flex;
            flex-direction: column;
            margin-top: -12px;
            margin-bottom: 64px;
        }
            .changePassword-container-main-notice-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--gray400);
            }
                .changePassword-container-main-notice-text ul {
                    margin: 0;
                    padding: 0;
                    margin-right: 2px;
                }
        .changePassword-container-main-inputs {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
            .changePassword-container-main-inputs > * {
                margin-bottom: 32px;
            }