.bannerSection-container {
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-align: center;
    height: auto;
}
    .bannerSection-container-img {
        position: relative;
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 100vh;
        margin: 0 auto;
    }
    .bannerSection-container-main {
        position: absolute; 
        top: 0; 
        width: 100%; 
        margin-top: 0;
        margin-left: 45px;
        margin-right: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100vh;
    }
        .bannerSection-container-main-title {
            color: var(--white);
            margin-bottom: 24px;
        }
        .bannerSection-container-main-textOne {
            color: var(--white);
            text-align: left;
        }
            .bannerSection-container-main-textOne strong {
                font-weight: 700;
            }
        .bannerSection-container-main-textThree {
            color: var(--white);
            text-align: left;
            margin-top: 24px;
            margin-bottom: 40px;
        }
            .bannerSection-container-main-textThree strong {
                font-weight: 700;
            }
        .bannerSection-container-main-buttonContainer{
            width: 260px;
        }

@media only screen and (max-width: 380px) {
    .bannerSection-container-main {
        align-items: center;
        justify-content: center;
        height: unset;
        margin: 0;
        margin-left: 15px;
        margin-top: 80px;
        width: 92.5%;
    }
        .bannerSection-container-main-buttonContainer{
            width: 100%;
        }
        .bannerSection-container-main-title{
            text-align: center;
            margin-bottom: 14px;
        }
        .bannerSection-container-main-textOne {
            text-align: center;
        }
        .bannerSection-container-main-textTwo {
            text-align: center;
        }
        .bannerSection-container-main-textThree {
            text-align: center;
        }
}
@media only screen and (min-width: 380px) and (max-width: 38.5em) {
    .bannerSection-container-main {
        align-items: center;
        justify-content: center;
        height: unset;
        margin: 0;
        margin-left: 15px;
        margin-top: 118px;
        width: 92.5%;
    }
        .bannerSection-container-main-buttonContainer{
            width: 100%;
        }
        .bannerSection-container-main-title{
            text-align: center;
        }
        .bannerSection-container-main-textOne {
            text-align: center;
        }
        .bannerSection-container-main-textTwo {
            text-align: center;
        }
        .bannerSection-container-main-textThree {
            text-align: center;
        }
}