.changePasswordSuccess-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .changePasswordSuccess-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
        .changePasswordSuccess-container-main-title {
            color: var(--primary500);
            text-align: center;
            margin-bottom: 24px;
        }
        .changePasswordSuccess-container-main-text {
            color: var(--gray700);
            text-align: center;
        }