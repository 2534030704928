.cardPayContainer-container {
    display: flex;
    height: 278.69px;
    width: 429.54px;
}
    @media only screen and (max-width: 1200px) {
        .cardPayContainer-container {
            height: 185.79px;
            width: 286.36px;
        }}
    @media only screen and (max-width: 37.5em) {
        .cardPayContainer-container {
            height: 185.79px;
            width: 286.36px;
        }}
    .cardPayContainer-container-cvvContainer {
        margin-left: auto;
        margin-top: 135px;
        margin-right: 80px;
    }
        @media only screen and (max-width: 1200px) {
            .cardPayContainer-container-cvvContainer {
                margin-top: 85px;
                margin-right: 50px;
            }}
        @media only screen and (max-width: 37.5em) {
            .cardPayContainer-container-cvvContainer {
                margin-top: 85px;
                margin-right: 50px;
            }}
        .cardPayContainer-container-cvvContainer-text {
            font-style: normal;
            font-weight: normal;
            font-size: 1.79274375rem;
            line-height: 34px;
            letter-spacing: 0.1em;
            color: var(--black);
            margin: 0;
        }
            @media only screen and (max-width: 1200px) {
                .cardPayContainer-container-cvvContainer-text {
                    font-size: 1.25rem;
                }}
            @media only screen and (max-width: 37.5em) {
                .cardPayContainer-container-cvvContainer-text {
                    font-size: 1.25rem;
                }}
                .cardPayContainer-container-titular-info{
                    margin-top: 146px;
                    margin-left: 15px;
                    
                }
                @media only screen and (max-width: 1200px) {
                    .cardPayContainer-container-titular-info{
                        margin-top: 90px;
                    }
                }
                @media only screen and (max-width: 37.5em) {
                    .cardPayContainer-container-titular-info {
                        margin-top: 80px;
                        width: 250px;
                    }
                }
                .numero{
                width: 370px;
                margin: auto;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: normal;
                font-size: 1.75rem;
                line-height: 30px;
                text-align: center;
                letter-spacing: 0.1em;
                }
                @media only screen and (max-width: 1200px) {
                    .numero {
                       font-size: 1.25rem;
                       width: 270px;
                    }
                }
                @media only screen and (max-width: 37.5em) {
                    .numero {
                       font-size: 1.25rem;
                       width: 250px;
                    }
                }
                
                .dos{
                    display: flex;
                    flex-direction: row;
                }
                .name{
                font-style: normal;
                font-weight: normal;
                font-size: 1.25rem;
                line-height: 24px;
                }
                .fecha{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.25rem;
                    line-height: 24px;
                    text-align: right;
                    padding-left: 90px; 
                }
                @media only screen and (max-width: 1200px) {
                    .name {
                       font-size: 0.9375rem;
                    }
                    .fecha{
                        font-size: 0.9375rem;
                        padding-left: 40px;
                    }
                }
                @media only screen and (max-width: 37.5em) {
                    .name {
                       font-size: 0.875rem;
                    }
                    .fecha{
                        font-size: 0.875rem;
                        padding-left: 50px;
                    }
                }