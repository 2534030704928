.FormFieldOk-container-main-tos {
    display: flex;
    flex-direction: row;
    align-items: center;
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldOk-container-main-tos {
            align-items: flex-start;
        }}
    .FormFieldOk-container-main-tos-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        border: 2px solid var(--tertiary500);
        margin-right: 10px;
        border-radius: 2px;
        cursor: pointer;
    }
        .FormFieldOk-container-main-tos-box-inner {
            height: 100%;
            width: 100%;
            background-color: var(--tertiary500);
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
        }
    .FormFieldOk-container-main-tos-text {
        color: var(--tertiary500);
    }
        .FormFieldOk-container-main-tos-text a {
            color: var(--secondary500);
            text-decoration: none;
            cursor: pointer;
        }