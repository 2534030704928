.info {
    margin: 0;
    margin-left: 20px;
    margin-right: 30px;
}

.title {
    font-style: normal;
    font-weight:bold;
    font-size: 1rem;
    line-height: 20px;
    color: var(--black);
    margin: 0;
}

.simple-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    color: var(--black);
    padding-left: 10px;
}