.checkOutProduct-container {
    display: flex;
    flex-direction: row;
    width: 93.5%;
    margin: auto;
    justify-content: space-between;
}
    @media only screen and (max-width: 37.5em) {
        .checkOutProduct-container {
            flex-direction: column;
            margin-bottom: 150px;
            justify-content: center;
            align-items: center;
        }}
    .checkOutProduct-container-main {
        width: 65%;
        margin-bottom: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProduct-container-main {
                width: 95%;
                margin-bottom: 0px;
                padding-bottom: 80px;
            }}
        .checkOutProduct-container-main-title {
            color: var(--gray700);
            margin: 24px 0;
        }
        .checkOutProduct-container-main-volver {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary500);
        }
        .checkOutProduct-container-main-list {
            border: 1px solid var(--primary500);
            border-radius: 20px;
            padding: 17px 40px;
        }
            .checkOutProduct-container-main-list-item {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin: 20px 0;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProduct-container-main-list-item {
                        width: 90%;
                    }}
            .checkOutProduct-container-main-list-title {
                color: var(--gray700);
            }
    .checkOutProduct-container-endWrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-right: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProduct-container-endWrapper {
                width: 100%;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: var(--white);
                z-index: 20;
                align-items: center;
                justify-content: center;
                box-shadow: 0px -4px 30px 0px rgba(0, 0, 0, 0.10);

            }}
        .checkOutProduct-container-endWrapper-main {
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutProduct-container-endWrapper-main {
                    width: 95%;
                    padding: 10px 0;
                }}
            .checkOutProduct-container-endWrapper-main-detailsWrapper {
                display: flex;
                flex-direction: column;
                width: 30%;
                margin-right: 20px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProduct-container-endWrapper-main-detailsWrapper {
                        width: 95%;
                        margin-right: 0px;
                    }}
                .checkOutProduct-container-detailsWrapper-buttonWrapper {
                    margin-bottom: 10px;
                }