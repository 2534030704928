.denyYesModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .denyYesModal-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .denyYesModal-container-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 24px 0;
    }
        .denyYesModal-container-data-text {
            text-align: center;
            color: var(--gray500);
        }
    .denyYesModal-container-botton {
        width: 30%;
    }
    @media only screen and (max-width: 37.5em) {
        .denyYesModal-container-botton {
            width: 90%;
        }}