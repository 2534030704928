.formFieldQuestionnaireItem-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--gray200);
    padding-bottom: 20px;
    margin-bottom: 20px;
}
    @media only screen and (max-width: 37.5em) {
        .formFieldQuestionnaireItem-container {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }}
    .formFieldQuestionnaireItem-containe-question {
        display: flex;
        flex-direction: row;
        width: 80%;
    }
        @media only screen and (max-width: 37.5em) {
            .formFieldQuestionnaireItem-containe-question {
                width: 100%;
            }}
        .formFieldQuestionnaireItem-containe-question-number {

        }
        .formFieldQuestionnaireItem-containe-question-question {

        }
    .formFieldQuestionnaireItem-containe-answer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 12.5%;
    }
        @media only screen and (max-width: 37.5em) {
            .formFieldQuestionnaireItem-containe-answer {
                width: 100%;
                justify-content: flex-start;
                margin-top: 10px;
            }}
        .formFieldQuestionnaireItem-containe-answer-choice {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
            @media only screen and (max-width: 37.5em) {
                .formFieldQuestionnaireItem-containe-answer-choice {
                    margin-right: 20px;
                }}
            .formFieldQuestionnaireItem-containe-answer-choice-text {
                color: var(--tertiary500);
                margin-right: 5px;
            }
            .formFieldQuestionnaireItem-containe-answer-choice-circle {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 15px;
                width: 15px;
                border: 1px solid var(--tertiary500);
                border-radius: 25px;
            }
                .formFieldQuestionnaireItem-containe-answer-choice-circle-inner {
                    height: 80%;
                    width: 80%;
                    border-radius: 25px;
                    background-color: var(--tertiary500);
                    transition: all 0.5s ease;
                }