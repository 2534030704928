.desactivarPolizaSuccessAlert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
    .desactivarPolizaSuccessAlert-container-close {
        margin-left: auto;
    }
    .desactivarPolizaSuccessAlert-container-text {
        text-align: center;
        color: #333333;
        margin: 32px 0;
    }
    .desactivarPolizaSuccessAlert-container-bot {
        width: 30%;
    }