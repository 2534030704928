.oferta-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .oferta-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .oferta-container-main-title {
        color: var(--gray700);
    }
    .oferta-container-main-subTitle {
        color: var(--gray400);
        text-align: center;
    }
        @media only screen and (max-width: 37.5em) {
            .oferta-container-main-subTitle {
                width: 90%;
            }
        }
    .oferta-container-main-planList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1440px;
        margin-top: 40px;
        margin-bottom: 38px;
    }
        @media only screen and (max-width: 37.5em) {
            .oferta-container-main-planList {
                overflow-x: scroll;
                width: 100%;
                justify-content: flex-start;
                flex-wrap: nowrap;
            }
        }
        .oferta-container-main-planList > * {
            margin: 10px;
        }
    .oferta-container-main-faqList {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin-top: 32px;
        margin-bottom: 48px;
    }
        @media only screen and (max-width: 37.5em) {
            .oferta-container-main-faqList {
                width: 90%;
            }
        }