.checkOutPayment-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}
    @media only screen and (max-width: 37.5em) {
        .checkOutPayment-container {
            flex-direction: column;
            margin-bottom: 150px;
        }}
    .checkOutPayment-container-detailsWrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-right: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutPayment-container-detailsWrapper {
                width: 95%;
                margin-right: 0px;
                margin-top: 20px;
            }}
    .checkOutPayment-container-main {
        width: 70%;
        margin-left: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutPayment-container-main {
                width: 97.5%;
                flex-direction: column;
                margin-left: 15px;
                padding-bottom: 100px;
            }}
        .checkOutPayment-container-main-cbuContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 40%;
            margin-top: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutPayment-container-main-cbuContainer {
                    width: 95%;
                }}
            .checkOutPayment-container-main-cbuContainer-inputContainer {
                width: 45%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutPayment-container-main-cbuContainer-inputContainer {
                        width: 100%;
                    }}
                .checkOutPayment-container-main-cbuContainer-inputContainer-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.5rem;
                    line-height: 29px;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 15px;
                }
                .checkOutPayment-container-main-cbuContainer-inputContainer-inputWrapper {
                    margin-left: -10px;
                }
                    @media only screen and (max-width: 37.5em) {
                        .checkOutPayment-container-main-cbuContainer-inputContainer-inputWrapper {
                            width: 95%;
                        }}
            .checkOutPayment-container-main-cbuContainer-textContainer {
                width: 45%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutPayment-container-main-cbuContainer-textContainer {
                        width: 100%;
                        margin-top: 30px;
                    }}
                .checkOutPayment-container-main-cbuContainer-textContainer-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.5rem;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 10px;
                }
                .checkOutPayment-container-main-cbuContainer-textContainer-description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.2rem;
                    color: #999999;
                    margin: 0;
                }
        .checkOutPayment-container-main-cvvInputContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutPayment-container-main-cvvInputContainer {
                    flex-direction: column;
                }}
            .checkOutPayment-container-main-cvvInputContainer-holder {
                display: flex;
                width: 50%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutPayment-container-main-cvvInputContainer-holder {
                        width: 100%;
                        align-items: center;
                        flex-direction: column;
                    }}
            .checkOutPayment-container-main-cvvInputContainer-details {
                display: flex;
                flex-direction: column;
                width: 45%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutPayment-container-main-cvvInputContainer-details {
                        width: 90%;
                    }}
        .checkOutPayment-container-main-optionsCards {
            display: flex;
            flex-direction: row;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutPayment-container-main-optionsCards {
                    flex-direction: column;
                }}
            .checkOutPayment-container-main-optionsCards-cards {
                display: flex;
                flex-direction: column;
                width: 50%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutPayment-container-main-optionsCards-cards {
                        width: 100%;
                        margin-top: 20px;
                    }}
                .checkOutPayment-container-main-optionsCards-cards-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.5rem;
                    line-height: 29px;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 20px;
                }
            .checkOutPayment-container-main-optionsCards-options {
                display: flex;
                flex-direction: column;
                width: 50%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutPayment-container-main-optionsCards-options {
                        width: 100%;
                    }}
                .checkOutPayment-container-main-optionsCards-options-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.5rem;
                    line-height: 29px;
                    color: var(--black);
                    margin: 0;
                    margin-bottom: 20px;
                }
                    @media only screen and (max-width: 37.5em) {
                        .checkOutPayment-container-main-optionsCards-options-title {
                            margin-top: 20px;
                        }}
        .checkOutPayment-container-main-title {
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 29px;
            color: var(--gray700);
            margin: 0;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutPayment-container-main-title {
                    margin-bottom: 30px;
                }}
        .checkOutPayment-container-main-subTitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            line-height: 24px;
            color: var(--gray500);
            margin: 0;         
            margin-top: 10px;
            margin-bottom: 30px;
        }
        .checkOutPayment-container-main-options {
            display: flex;
            flex-direction: row;
            overflow-y: scroll;
            padding-bottom: 5px;
        }
.checkOutPayment-container-main-infoCard{
    margin-top: 26px;
    width: 50%;
}
    .checkOutPayment-container-main-infoCard-input {
        width: 70%;
    }

.cardPayContainer-container {
    display: flex;
}

.titulo{
font-style: normal;
font-weight: normal;
font-size: 1.5rem;
line-height: 29px;
padding-left: 20px;
color: var(--black);
}

.subContenedor{
    display: flex;
    flex-direction: row;
    margin: 0;
}
@media only screen and (max-width: 38.5em) {
    .subContenedor {
        flex-direction: column;
        margin-left: 0;
        justify-content: center;
        align-items: center;
    }
    .formulario-container{
        margin-left: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        position: relative;
        right: 1em;
    }
    .checkOutPayment-container-main-infoCard{
        width: 95%;
    }
    .titulo{
        padding-left: 20px;
        }
}
.formulario-container{
    margin-left: 40px;
    margin-right: 10px;
}

.formulario-title{
    color: var(--gray700);
}

.emisor-container{
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px 8px;
}

.subTitle{
font-style: normal;
font-weight: bold;
font-size: 1.25rem;
line-height: 24px;
color: var(--black);
opacity: 0.4;
padding-left: 10px;
}
.form-div-uno{
    display: flex;
    flex-direction: row;
}

.emisorOne{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 16px 0;
    /* flex-basis: content; */
}
    .emisorOne > *{
        margin-right: 8px;
        margin-bottom: 8px;
    }

.checkOutPayment-Selector-Pago-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

@media only screen and (max-width: 37.5em) {
    .checkOutPayment-Selector-Pago-container{
        overflow-x: scroll;
        flex-wrap: nowrap;
        padding-bottom: 10px;
    }
}
