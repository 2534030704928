.contact-container {
    display: flex;
    flex-direction: column;
}
    .contact-container-main {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
        .contact-container-main-left {
            display: flex;
            flex-direction: column;
            width: 42.5%;
            margin: 0 auto;
            margin-top: 90px;
        }
            @media only screen and (max-width: 37.5em) {
                .contact-container-main-left {
                    width: 92%;
                }}
            .contact-container-main-left-maxLength {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
                .contact-container-main-left-maxLength-text {
                    color: var(--gray300);   
                }
            .contact-container-main-left-title {
                color: var(--primary700);     
                margin-bottom: 10px;
            }
                @media only screen and (max-width: 37.5em) {
                    .contact-container-main-left-title {
                        margin-bottom: 10px;
                    }}
            .contact-container-main-left-subTitle {
                color: var(--gray400);
            }
            .contact-container-main-left-inputs {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-left: -10px;
            }
                .contact-container-main-left-inputs-inputWrapper {
                    width: 100%;
                }
                .contact-container-main-left-inputs-list {
                    display: flex;
                    flex-direction: row;
                    width: 103.5%;
                    margin: 20px 0;
                }
                    .contact-container-main-left-inputs-list > * {
                        margin: 0 10px;
                    }
                    @media only screen and (max-width: 37.5em) {
                        .contact-container-main-left-inputs-list {
                            width: 105%;
                            flex-wrap: wrap;
                            margin: 0px 0;
                            align-items: center;
                            justify-content: center;
                        }
                        .contact-container-main-left-inputs-list > *{
                            width: 95%;
                            flex-wrap: wrap;
                            margin: 10px 0;
                        }
                    }
            .contact-container-main-left-textareaTitle {
                color: var(--tertiary500);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .contact-container-main-left-textarea {
                outline: none;
                resize: none;
                height: 150px;
                width: 93.6%;
                background: var(--gray100);
                border-radius: 25px;
                border: none;
                padding: 10px 20px;
                color: var(--gray700);
                font-family: Lato, sans-serif;
            }
                @media only screen and (max-width: 37.5em) {
                    .contact-container-main-left-textarea {
                        width: 90%;
                        font-size: 1.25rem;
                    }}
            .contact-container-main-left-sendWrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                margin: 20px 0;
            }
                .contact-container-main-left-sendWrapper > * {
                    margin: 5px 0;
                }
                @media only screen and (max-width: 37.5em) {
                    .contact-container-main-left-sendWrapper {
                        flex-direction: column;
                        flex-wrap: nowrap;
                        margin: 0;
                    }}
                .contact-container-main-left-sendWrapper-captcha {
                    align-self: flex-end;
                }
                    @media only screen and (max-width: 37.5em) {
                        .contact-container-main-left-sendWrapper-captcha {
                            align-self: center;
                            margin: 10px 0;
                        }}
                .contact-container-main-left-sendWrapper-buttonWrapper {
                    width: 50%;
                    margin-left: auto;
                }
                    @media only screen and (max-width: 37.5em) {
                        .contact-container-main-left-sendWrapper-buttonWrapper {
                            width: 100%;
                            margin-bottom: 20px;
                        }}
            .contact-container-main-left-canal {
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
            }
                @media only screen and (max-width: 37.5em) {
                    .contact-container-main-left-canal {
                        margin-bottom: 10px;
                    }}
                .contact-container-main-left-canal-left {
                    margin-right: 5px;
                }
                .contact-container-main-left-canal-right {

                }
                    .contact-container-main-left-canal-right-title {
                        font-style: normal;
                        font-weight: 325;
                        font-size: 1rem;
                        color: var(--primary500);  
                        margin: 0;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .contact-container-main-left-canal-right-title {
                                font-size: 0.9rem;
                            }}
                    .contact-container-main-left-canal-right-text {
                        font-style: normal;
                        font-weight: 325;
                        font-size: 0.9rem;
                        color: var(--gray800);
                        margin: 0;
                    }
                        @media only screen and (max-width: 37.5em) {
                            .contact-container-main-left-canal-right-text {
                                font-size: 0.8rem;
                            }}
        .contact-container-main-right {
            display: flex;
            flex-direction: column;
            width: 50%;
        }
            @media only screen and (max-width: 37.5em) {
                .contact-container-main-right {
                    display: none;
                }}
            .contact-container-main-right-img {
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }