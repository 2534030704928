.burgerMenuItem-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 5px;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
    width: 90%;
    margin-bottom: 16px;
}
    .burgerMenuItem-container-text {
        align-items: center;
        color: var(--primary500);
        margin: 0;
        margin-left: 10px;
    }