
.FormFieldPhotoItem-container {
    width: 100%;
}
    .FormFieldPhotoItem-container-overLay {
        display: flex;
        position: absolute;
        background-color: transparent;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 9999;
    }
    .FormFieldPhotoItem-container-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: var(--gray50);
        border: 1.5px solid var(--primary500);
        border-radius: 100px;
        padding: 10px 0;
        cursor: pointer;
        transition: all 0.1s ease-in;
    }
        .FormFieldPhotoItem-container-main:active {
            transform: scale(0.95);
        }
        .FormFieldPhotoItem-container-main-text {
            color: var(--primary500);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .FormFieldPhotoItem-container-main-input {
            display: none;
        }
        .FormFieldPhotoItem-container-main-noImage {
            display: flex;
            width: 90%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        .FormFieldPhotoItem-container-main-yesImage {
            display: flex;
            width: 90%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

.FormFieldPhotoItem-container-loader {
    width: 18px;
    height: 18px;
    border: 2px solid var(--primary500);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: auto;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 