.circulacion-container {
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}
  .circulacion-container-main {
    display: flex;
    flex-direction: column;
    width: 94%;
    margin-top: 24px;
  }
    .circulacion-container-main-button {
      width: 15%;
      margin: auto;
      margin-bottom: 40px;
    }
      @media only screen and (max-width: 37.5em) {
        .circulacion-container-main-button {
          width: 100%;
        }
      }
    .circulacion-container-main-note {
      color: var(--gray400);
      text-align: center;
      margin: auto;
      margin-top: 24px;
      margin-bottom: 32px;
    }
    .circulacion-container-main-title {
      color: var(--gray700);
      margin-bottom: 24px;
    }
    .circulacion-container-main-card {
      display: flex;
      flex-direction: column;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 4px 6px 20px 0px rgba(0, 0, 0, 0.16);
      margin-bottom: 32px;
      width: 435px;
      margin: auto;
    }
      @media only screen and (max-width: 37.5em) {
        .circulacion-container-main-card {
          width: 100%;
        }
      }
      .circulacion-container-main-card-top {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        width: 100%;
        padding: 10px 0;
      }
        .circulacion-container-main-card-top-imgWrapper {
          width: 90%;
        }
          .circulacion-container-main-card-top-imgWrapper-img {
            width: 50%;
          }
      .circulacion-container-main-card-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--primary500);
        padding: 10px 0;
        width: 100%;
      }
        .circulacion-container-main-card-main-product {
          color: var(--gray100);
          margin-left: 20px;
          margin-bottom: 14.5px;
        }
        .circulacion-container-main-card-main-section {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          width: 91%;
          margin-bottom: 37.5px;
        }
          .circulacion-container-main-card-main-section-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 8px;
          }
            .circulacion-container-main-card-main-section-item-text {
              color: var(--gray50);
            }
            .circulacion-container-main-card-main-section-item-subItem {
              display: flex;
              flex-direction: column;
              width: 50%;
            }
              .circulacion-container-main-card-main-section-item-subItem-text {
                color: var(--gray50);
                text-align: right;
              }