.contactModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 725px;
    margin: auto;
}
    @media only screen and (max-width: 37.5em) {
        .contactModal-container {
            width: 90%;
        }}
.contactModal-containerSucc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 430px;
    margin: auto;
}
    @media only screen and (max-width: 37.5em) {
        .contactModal-containerSucc {
            width: 90%;
        }}
    .contactModal-container-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
        .contactModal-container-top-close {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 30px;
        }
        .contactModal-container-top-title {
            color: var(--gray700);
            margin-bottom: 16px;
        }
    .contactModal-container-subTitle {
        color: var(--gray300);
        margin-bottom: 16px;
        text-align: center;
    }
    .contactModal-container-inputList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .contactModal-container-inputList {
                flex-wrap: nowrap;
                flex-direction: column;
            }}
        .contactModal-container-inputList > * {
            width: 48%;
            margin: 16px 0;
        }
            @media only screen and (max-width: 37.5em) {
                .contactModal-container-inputList > * {
                    width: 108%;
                }}
    .contactModal-container-finish {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
    }
    @media only screen and (max-width: 37.5em) {
        .contactModal-container-finish {
            flex-direction: column;
            justify-content: center;
        }}
        .contactModal-container-finish-captcha {
        }
        .contactModal-container-finish-botons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .contactModal-container-finish-botons {
                    margin-top: 16px;
                }}
    .contactModal-container-lottie {
        margin: 20px 0;
    }
    .contactModal-container-boton {
        width: 50%;
    }
    @media only screen and (max-width: 37.5em) {
        .contactModal-container-boton {
            width: 100%;
        }}