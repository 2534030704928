.passwordRecovery-container{
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}
    .passwordRecovery-container-title {
        display: flex;
        width: 100%;
        background-color: var(--primary500);
        align-items: center;
        justify-content: center;
        height: 114px;
    }
        .passwordRecovery-container-title-text {
            color: var(--gray50)
        }
    .passwordRecovery-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 429px;
        margin: 0 auto;
    }
        @media only screen and (max-width: 37.5em) {
            .passwordRecovery-container-main {
                width: 91%;
            }}
        .passwordRecovery-container-main > *{
            margin-top: 24px;
        }
        .passwordRecovery-container-main-title {
        }
        .passwordRecovery-container-main-button {
            width: 85%;
        }
        @media only screen and (max-width: 37.5em) {
            .passwordRecovery-container-main-button {
                width: 100%;
            }}