.cardSelectorCheckOutComponent-container {
    display: flex;
    flex-direction: row;
    width: 95%;
    background: var(--white);
    border: 0.789286px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 7.89286px 7.89286px 0px 0px;
    align-items: center;
    transition: all 0.5s ease;
    min-height: 70px;
    border: 1px solid var(--gray100);
}
.cardSelectorCheckOutComponent-containerSelected {
    display: flex;
    flex-direction: row;
    width: 95%;
    border: 0.789286px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 7.89286px 7.89286px 0px 0px;
    align-items: center;
    transition: all 0.5s ease;
    animation-name: backgroundColorPalette;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: linear;      
    background: linear-gradient(to right, #EFEFEF 50%, var(--white) 50%) left;
    background-size: 200%;
    min-height: 70px;
    border: 1px solid var(--gray100);
}
@keyframes backgroundColorPalette {
    0% {background-position: right;}
    100% {background-position: left;}
}
    .cardSelectorCheckOutComponent-container-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 20px;
        min-width: 20px;
        border: 0.5px solid var(--primary500);
        border-radius: 25px;
        margin: 0px 20px;
        padding: 0;
        cursor: pointer;
    }
        .cardSelectorCheckOutComponent-container-circle-innerCircle {
            display: flex;
            min-height: 12px;
            min-width: 12px;
            background-color: var(--primary500);
            border-radius: 25px;
            margin: 0px;
            padding: 0;
            transition: all 0.5s ease;
        }
    .cardSelectorCheckOutComponent-container-name {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 20px;
        color: var(--primary500);
        margin-left: 20px;
    }