.contactExito-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}
    .contactExito-container-main {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
        .contactExito-container-main-left {
            display: flex;
            flex-direction: column;
            width: 42.5%;
            margin: 0 auto;
            margin-top: 70px;
            align-items: center;
            justify-content: center;
        }
            @media only screen and (max-width: 37.5em) {
                .contactExito-container-main-left {
                    width: 92%;
                }}
            .contactExito-container-main-left-title {
                text-align: center;
                color: var(--primary500);
            }
                @media only screen and (max-width: 37.5em) {
                    .contactExito-container-main-left-title {
                        margin-top: 20px;
                    }}
            .contactExito-container-main-left-subTitle {
                text-align: center;
                color: var(--black);     
                margin-top: 10px;
                margin-bottom: 20px;
            }
                @media only screen and (max-width: 37.5em) {
                    .contactExito-container-main-left-subTitle {
                        margin-bottom: 80px;
                    }}
            .contactExito-container-main-left-buttonWrapper {
                width: 50%;
            }
                @media only screen and (max-width: 37.5em) {
                    .contactExito-container-main-left-buttonWrapper {
                        width: 95%;
                        margin-bottom: 50px;
                    }}
        .contactExito-container-main-right {
            display: flex;
            flex-direction: column;
            width: 50%;
        }
            @media only screen and (max-width: 37.5em) {
                .contactExito-container-main-right {
                    display: none;
                }}
            .contactExito-container-main-right-img {
                width: 100%;
                margin: 0 auto;
            }