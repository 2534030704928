.emptyRefererModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .emptyRefererModal-container-title {
        color: var(--gray700);
        margin: 24px 0;
    }
    .emptyRefererModal-container-codeError {
        color: var(--statusErrorPrimary);
    }
    .emptyRefererModal-container-input {
        width: 70%;
    }
    .emptyRefererModal-container-button {
        width: 50%;
        margin: 24px 0;
    }