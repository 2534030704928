.verificacionEmail-container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;
}

.verificacionEmail-container-main-title {
    font-style: normal;
    font-weight: normal;
    font-size: 2.1875rem;
    line-height: 32px;
    text-align: center;
    color: var(--black);
    width: 30%;
    margin: 30px 0px;
}

.verificacionEmail-container-main-subTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 29px;
    text-align: center;
    color: var(--black);
    width: 30%;     
    margin-bottom: 45px;
}


.verificacionEmail-container-main-codeContainer {
    display: flex;
    margin-bottom: 40px;
}

.verificacionEmail-container-main-codeContainer-inputContainer {
    width: 55px;
    height: 70px;
    border: 2px solid transparent;
    box-sizing: border-box;
    margin: 0 4px;
}

.verificacionEmail-container-main-codeContainer-inputContainer-input {
    font-style: normal;
    font-weight: normal;
    font-size: 2.1875rem;
    text-align: center;
    color: var(--black);
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 2px solid var(--gray200);
    outline-color: var(--primary500);
    box-sizing: border-box;
    border-radius: 15px;
}

.verificacionEmail-container-main-resendText {
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 24px;
    text-align: center;
    color: var(--black);
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.verificacionEmail-container-main-buttonWrapper {
    width: 30%;
    margin-bottom: 10px;
}

@media only screen and (max-width: 37.5em) {
    .verificacionEmail-container-main-title {
        width: 80%;
        font-size: 1.5625rem;
        line-height: 25px;
    }
    .verificacionEmail-container-main-subTitle {
        width: 80%;
        font-size: 1.15rem;
        line-height: 19px;
    }

    .verificacionEmail-container-main-codeContainer-inputContainer {
        width: 50px;
        height: 65px;
        margin: 0 4px;
    }

    .verificacionEmail-container-main-buttonWrapper {
        width: 90%;
        margin-bottom: 10px;
    }
    
}

@media only screen and (max-width: 340px){
    .verificacionEmail-container-main-codeContainer-inputContainer {
        width: 45px;
        height: 60px;
        margin: 0 3px;
    }
}