.searchInputComponent-inputContainerFocus {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 15px;
      border: 1px solid var(--primary500);
      background: var(--white);
      width: 40%;
      padding: 9px 10px;
}
.searchInputComponent-inputContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 15px;
      border: 1px solid var(--gray200);
      background: var(--white);
      width: 40%;
      padding: 9px 10px;
}
      .searchInputComponent-inputContainer-input {
            border: none;
            color: var(--gray700);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
            margin: 0;
            padding: 0;
            width: 97%;
            outline: none;
            background-color: transparent;
      }
            .searchInputComponent-inputContainer-input::placeholder {
                  color: var(--gray300);
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 1rem;
            }
      .searchInputComponent-inputContainer-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primary500);
            width: 3%;
            cursor: pointer;
            background-color: transparent;
      }