.errorComponent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    min-width: 300px;
}
    .errorComponent-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .errorComponent-container-title {
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 19px;
        text-align: center;
        color: var(--primary500);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        margin-bottom: 10px;
    }
    .errorComponent-container-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: var(--primary500);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
    }