.formFieldQuestionnaire-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .formFieldQuestionnaire-container-title {
        color: var(--gray700);
        margin-bottom: 24px;
    }
        .formFieldQuestionnaire-container-main {
            border-radius: 20px;
            border: 1px solid var(--primary300);
            background: var(--gray50);
            display: flex;
            flex-direction: column;
            padding: 17px 40px;
        }
            @media only screen and (max-width: 37.5em) {
                .formFieldQuestionnaire-container-main {
                    padding: 17px 20px;
                }}
            .formFieldQuestionnaire-container-main-subtitle {
                color: var(--gray700);
                margin-bottom: 32px;
            }
                @media only screen and (max-width: 37.5em) {
                    .formFieldQuestionnaire-container-main-subtitle {
                        text-align: center;
                        width: 80%;
                        margin: 0 auto;
                        margin-bottom: 32px;
                    }}
            .formFieldQuestionnaire-container-main-tos {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
            }
                @media only screen and (max-width: 37.5em) {
                    .formFieldQuestionnaire-container-main-tos {
                        align-items: flex-start;
                    }}
                .formFieldQuestionnaire-container-main-tos-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 15px;
                    width: 15px;
                    border: 2px solid var(--tertiary500);
                    margin-right: 10px;
                    border-radius: 2px;
                }
                    .formFieldQuestionnaire-container-main-tos-box-inner {
                        height: 100%;
                        width: 100%;
                        background-color: var(--tertiary500);
                        transition: all 0.5s ease;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--white);
                    }
                .formFieldQuestionnaire-container-main-tos-text {
                    color: var(--tertiary500);
                }