.atencionAlert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .atencionAlert-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .atencionAlert-container-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    }