.misReclamos-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
    .misReclamos-container-main {
        display: flex;
        flex-direction: column;
        width: 94%;
    }
        @media only screen and (max-width: 37.5em) {
            .misReclamos-container-main {
                width: 92%;
            }
        }
        .misReclamos-container-main-title {
            color: var(--gray700);
            margin: 24px 0;
        }
        .misReclamos-container-main-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
        }
            .misReclamos-container-main-list > * {
                margin: 0 10px;
                margin-bottom: 10px;
            }
            @media only screen and (max-width: 37.5em) {
                .misReclamos-container-main-list > * {
                    margin: 0;
                    margin-bottom: 24px;
                    width: 100%;
                }
            }
        .misReclamos-container-main-emptyList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
            .misReclamos-container-main-emptyList-text {
                color: var(--gray400);
            }