.demoColorSelector-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .demoColorSelector-container-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .demoColorSelector-container-list > * {
            margin: 5px 0;
        }
        .demoColorSelector-container-list-colorList {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            position: relative;
            align-items: center;
            justify-content: center;
        }
            .demoColorSelector-container-list-colorList-box {
                display: flex;
                height: 80px;
                width: 40px;
                border-radius: 60;
            }
            .demoColorSelector-container-list-colorList-check {
                position: absolute;
                border: 2px solid black;
                height: 40px;
                width: 40px;
                border-radius: 50px;
            }