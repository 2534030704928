.misCoberturas-container {
    padding:0;
    margin:0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
    .misCoberturas-container-main {
        display: flex;
        flex-direction: column;
        width: 94%;
        align-self: center;
    }
        @media only screen and (max-width: 37.5em) {
            .misCoberturas-container-main {
                width: 100%;
            }
        }
        .misCoberturas-container-main-title {
            margin-top: 32px;
            margin-bottom: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .misCoberturas-container-main-title {
                    margin-left: 20px;
                }
            }
        .misCoberturas-container-main-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 32px;
        }
            .misCoberturas-container-main-list > * {
                margin-right: 10px;
                margin-bottom: 10px;
            }
            @media only screen and (max-width: 37.5em) {
                .misCoberturas-container-main-list > * {
                    margin: 10px;
                }
            }
        .misCoberturas-container-main-emptyList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-top: 100px;
        }
            .misCoberturas-container-main-emptyList-text {
                color: var(--gray200);
                margin: 24px 0;
            }
            .misCoberturas-container-main-emptyList-bot {
                width: 50%;
            }