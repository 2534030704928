.FormFieldPhoto-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .FormFieldPhoto-container-description {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--gray500);
    }
        .FormFieldPhoto-container-description ul {
            font-style: normal;
            font-weight: bold;
            font-size: 1.25rem;
            line-height: 24px;
            color: red;
            margin: 0;
            padding: 0;
        }
    .FormFieldPhoto-container-list {
        display: flex;
        flex-direction: column;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldPhoto-container-list {
                flex-direction: row;
                overflow: scroll;
                padding-bottom: 10px;
            }}