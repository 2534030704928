.container-main-noticeText{
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #999999;
    margin: 0;
    margin: 2px 0;  
}
.changePassword-container-form{
    display: flex;
    flex-direction: column;
    width: 420px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

@media only screen and (max-width: 37.5em) {
    .changePassword-container-form {
        width: 90%;
        margin-top: 0;
    }
}

.passwordRecoveryForm-container-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: auto;
    width: 30%;
    margin-top: 40px;
}
    @media only screen and (max-width: 37.5em) {
        .passwordRecoveryForm-container-main {
            width: 90%;
            margin-top: 20px;
        }
    }
    .passwordRecoveryForm-container-main > *{ 
        margin-bottom: 20px;
    }