.numeroCotizacionModal-container {
    display: flex;
    flex-direction: column;
}
    .numeroCotizacionModal-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .numeroCotizacionModal-container-main-title {
            margin: 8px 0;   
            color: var(--gray700);
        }
        .numeroCotizacionModal-container-main-text {
            color: var(--gray400);
            text-align: center;
        }