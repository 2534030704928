.FormFieldSlider-inputContainer {
    width: 100%;
}
    .FormFieldSlider-inputContainer-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        color: #999999;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        text-overflow: ellipsis;
    }
    .FormFieldNumber-inputContainer-sliderContainer {
        width: 100%;
        margin-top: 30px;
    }
        .FormFieldNumber-inputContainer-sliderContainer-minMaxContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
        }
            .FormFieldNumber-inputContainer-sliderContainer-minMaxContainer-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                color: var(--primary500);
                margin: 0;
            }