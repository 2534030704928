.burgerMenu-container { 
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 400px;
    min-width: 300px;
    height: 100%;
    z-index: 10000000;
    position: fixed;
    transition: all 1s ease;
    background-color: var(--white);
}
    @media only screen and (max-width: 37.5em) {
        .burgerMenu-container { 
            width: 70%;
            min-width: unset;
            max-width: unset;
        }
    }
    .burgerMenu-container-logo {
        width: 50%;
        margin: 40px 0;
    }
    .burgerMenu-container-top {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--primary500);
        align-items: center;
        justify-content: center;
        height: 40%;
    }
        .burgerMenu-container-top-ingresar {
            display: flex;
            background-color: var(--secondary500);
            border-radius: 25px;
            padding: 10px 40px;
            margin-bottom: 40px;
            cursor: pointer;
            text-decoration: none;
        }
            .burgerMenu-container-top-ingresar-text {
                font-style: normal;
                font-weight: 500;
                font-size: 1.25rem;
                text-align: center;
                color: var(--white);
                margin: 0;
            }
            @media only screen and (max-width: 37.5em) {
                .burgerMenu-container-top-ingresar-text {
                    font-size: 1rem;
                }
            }
        .burgerMenu-container-top-user {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
        }
            .burgerMenu-container-top-user-name {
                text-align: center;
                color: var(--white);
            }
            .burgerMenu-container-top-user-email {
                text-align: center;
                color: var(--white);
                margin-top: 5px;
            }
        .burgerMenu-container-top-profileImg {
            margin: 0 auto;
            width: 125px;
            height: 125px;
            border-radius: 100px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        @media only screen and (max-width: 37.5em) {
            .burgerMenu-container-top-profileImg {
                width: 100px;
                height: 100px;
            }
        }
    .burgerMenu-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: auto;
        height: 60%;
        padding-top: 27px;
    }
    .burgerMenu-container-bot {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid var(--primary500);
        padding-bottom: 8px;
        padding-top: 8px;
        margin-top: auto;
    }
        .burgerMenu-container-bot-text {
            text-align: right;
            color: var(--primary500);
            cursor: pointer;
            padding: 19px 10px;
            margin-right: 19px;
            width: 85%;
            transition: all 0.25s ease-in;
        }
        .burgerMenu-container-bot-text:active {
            background-color: var(--gray50);
            border-radius: 15px;
            color: var(--secondary500);
        }