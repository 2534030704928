.checkOutResumePaymentComponent-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: var(--white);
    box-shadow: 0px 4.12621px 8.25243px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    align-items: center;
}
    .checkOutResumePaymentComponent-container-img {
        width: 15%;
        margin: 0;
        margin-left: 20px;
        margin-right: 30px;
    }
    .checkOutResumePaymentComponent-container-text {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 20px;
        color: var(--black);
        margin: 0;
    }
    .checkOutResumePaymentComponent-container-check {
        margin-left: auto;
        margin-right: 20px;
    }