.categoriaButton-container {
    display: flex;
    height: 264px;
    min-width: 264px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: 15px;
    transition: all 0.25s ease-in;
    cursor: pointer;
    position: relative;
}
    @media only screen and (max-width: 37.5em) {
        .categoriaButton-container {
        }}
    .categoriaButton-container:active {
        transform: scale(0.95);
    }
    .categoriaButton-container-imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40%;
    }
        .categoriaButton-container-imageContainer-image {
            width: 50%;
            height: auto;
        }
    .container-container-text {
        text-align: center;
        color: var(--gray700);
        width: 40%;
    }
    .categoriaButton-container-tag {
        position: absolute;
        top: 10px;
        right: 12px;
        color: var(--white);
        background-color: var(--secondary500);
        border-radius: 5px;
        padding: 5px 8px;
    }