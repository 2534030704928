.FormFieldSelector-container {
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldSelector-container {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }}
    .FormFieldSelector-container-selector {
        display: flex;
        transition: height 0.6s ease-in;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: var(--white);
        border: 1.5px solid var(--accent500);
        box-sizing: border-box;
        border-radius: 25px;
        padding: 10px 20px;
    }
        .FormFieldSelector-container-FormFieldSelector-value {
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: var(--primary500);
            margin: 0;
            padding-left: 15px;
            border: 0;
            background: transparent;
            outline: 0;
            width: 92.5%;
        }
        .FormFieldSelector-container-FormFieldSelector-lupa {
            transition: all 0.6s ease-in;
            display: flex;
            background: var(--accent500);
            border-radius: 0px 25px 25px 0px;
            align-items: center;
            justify-content: center;
            padding: 10px;
            width: 7.5%;
        }
    .FormFieldSelector-container-list { 
        transition: all 0.6s ease-in;
        overflow-y: scroll;
        background: var(--white);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        border-radius: 0px 0px 25px 25px;
        position: absolute;
        z-index: 10;
    }
        .FormFieldSelector-container-list-popularText {
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375rem;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: var(--gray200);
            margin: 0;
            margin-top: 10px;
            padding-left: 25px;
        }
        .FormFieldSelector-container-list-line {
            height: 1px;
            background-color: var(--gray200);
            width: 95%;
            margin-left: 25px;
        }
        .FormFieldSelector-container-list-listItemText {
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: var(--primary500);
            margin: 0;
            cursor: pointer;
            padding: 10px 0;
            padding-left: 25px;
        }
            .FormFieldSelector-container-list-listItemText:active {
                background-color: var(--accent500);
                color: var(--white)
            }