.paymentCard-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    border-bottom: 1px solid var(--gray100);
    background: var(--white);
    height: 64px;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 32px;
    width: 93%;
}
    .paymentCard-container-card {
        display: flex;
        align-items: center;
        justify-content: center;
    }