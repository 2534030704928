.FormFieldDefault-inputContainer {
    width: 100%;
}
    .FormFieldDefault-inputContainer-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        color: #999999;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        text-overflow: ellipsis;
    }
    .FormFieldDefault-inputContainer-wrapper {
        margin-left: -10px;
        margin-top: -5px;
    }