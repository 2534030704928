.paymentCardComponent-container {
    background: var(--white);
    box-shadow: 0px 3.10781px 6.21561px rgba(0, 0, 0, 0.1);
    border-radius: 11.6543px;
    display: flex;
    flex-direction: column;  
    width: 99.45px;
    height: 90.13px;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
}
    .paymentCardComponent-container:active {
        background-color: var(--primary500);
        transform: scale(0.9);
    }
    .paymentCardComponent-container:active * {
        color: var(--white);
        fill: var(--white);
    }
    .paymentCardComponent-container-name {
        font-style: normal;
        font-weight: 500;
        font-size: 0.6798rem;
        line-height: 19px;
        text-align: center;
        color: var(--primary500);
        margin: 0;
    }