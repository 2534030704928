.cerrarSesionAlert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .cerrarSesionAlert-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .cerrarSesionAlert-container-text {
        color: var(--gray700);
        margin: 32px 0;
    }
    .cerrarSesionAlert-container-botons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }