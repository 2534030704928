.successCard-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #27AE60;
    width: 427px;
    height: 55px;
    position: absolute;
    right: 0;
    transition: all 2s ease;
    z-index: 1000;
}
    @media only screen and (max-width: 37.5em) {
        .successCard-container {
            width: 300px;
            height: 55px;
        }}
    .successCard-container-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
    }
        .successCard-container-left-text {
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            line-height: 19px;
            color: var(--white);
            margin: 0;
            margin-left: 10px;
        }
            @media only screen and (max-width: 37.5em) {
                .successCard-container-left-text {
                    font-size: 0.75rem;
                }}
    .successCard-container-right {
        margin-right: 10px;
    }