.passwordRecoveryFinish-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}
    .passwordRecoveryFinish-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 30%;
    }
        @media only screen and (max-width: 37.5em) {
            .passwordRecoveryFinish-container-main {
                width: 80%;
            }
        }
        .passwordRecoveryFinish-container-main-title {
            color: var(--primary500);
            margin-bottom: 10px;
        }
        .passwordRecoveryFinish-container-main-text {
            text-align: center;
        }