.FormFieldPersonChile-container {
    display: flex;
    flex-direction: column;
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldPersonChile-container {
            justify-content: center;
            align-items: center;
            width: 90%;
            align-self: center;
            margin: 0 auto;
        }}
    .FormFieldPersonChile-container-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldPersonChile-container-main {
                flex-direction: column;
                justify-content: center;
            }}
        .FormFieldPersonChile-container-main-inputList {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-left: -10px;
            margin-bottom: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonChile-container-main-inputList {
                    flex-direction: column;
                }}
            .FormFieldPersonChile-container-main-inputList-two {
                width: 47.5%;
            }
                @media only screen and (max-width: 37.5em) {
                    .FormFieldPersonChile-container-main-inputList-two {
                        width: 100%;
                    }}
                .FormFieldPersonChile-container-main-inputList-two-row {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                }
                    .FormFieldPersonChile-container-main-inputList-two-row-firstWrapper {
                        width: 65%;
                    }
                    .FormFieldPersonChile-container-main-inputList-two-row-secondWrapper {
                        width: 30%;
                    }
                .FormFieldPersonChile-container-main-inputList-two-errorText {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.9rem;
                    line-height: 24px;
                    color: #FE1039;
                    margin: 0;
                    margin-bottom: -24px;
                    margin-left: 10px;
                }
            .FormFieldPersonChile-container-main-inputList-inputWrapper {
                width: 47.5%;
            }
                @media only screen and (max-width: 37.5em) {
                    .FormFieldPersonChile-container-main-inputList-inputWrapper {
                        width: 100%;
                    }}
        .FormFieldPersonChile-container-main-selectorList {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 16px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonChile-container-main-selectorList {
                    flex-direction: column;
                }}
            .FormFieldPersonChile-container-main-selectorList-item {
                display: flex;
                flex-direction: column;
                width: 47.5%;
                margin-bottom: 22px;
            }
                @media only screen and (max-width: 37.5em) {
                    .FormFieldPersonChile-container-main-selectorList-item {
                        width: 100%;
                    }}
                .FormFieldPersonChile-container-main-selectorList-item-selectorWrapper {
                    width: 100%;
                }
                    @media only screen and (max-width: 37.5em) {
                        .FormFieldPersonChile-container-main-selectorList-item-selectorWrapper {
                            width: 112%;
                            margin-left: -20px;
                        }}
                .FormFieldPersonChile-container-main-selectorList-item-label {
                    display: flex;
                    flex-direction: row;
                }
                    .FormFieldPersonChile-container-main-selectorList-item-label-text {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: #0075BC;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0;
                    }
                    .FormFieldPersonChile-container-main-selectorList-item-label-requiered {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: red;
                        margin: 0;
                    }
        .FormFieldPersonChile-container-main-buttonWrapper {
            width: 15%;
            margin-left: auto;
            margin-bottom: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonChile-container-main-buttonWrapper {
                    width: 100%;
                }}