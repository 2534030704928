.quoteQuestion-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}
    .quoteQuestion-container-volver {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin-bottom: 10px;
    }
        .quoteQuestion-container-volver-text {
            color: var(--primary500);
        }
    .quoteQuestion-container-step {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
        .quoteQuestion-container-step-paso {
            color: var(--gray400);
        }
        .quoteQuestion-container-step-pregunta {
            color: var(--gray700)
        }